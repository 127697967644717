<template>
  <div class="mb-3">
    <label v-if="label" :for="id" class="form-label" v-text="label"></label>
    <input
      :type="type"
      class="form-control custom-input"
      :id="id"
      :value="lValue"
      :required="required"
      :readonly="!editable"
      :name="name"
      :autocomplete="autocomplete"
      v-on:input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
import { generateUUID } from "@/plugins/uuidGenerator";

export default {
  name: "Input",
  props: {
    path: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      require: true,
    },
    type: {
      type: String,
      require: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    name: String,
    autocomplete: String,
    label: String,
  },
  data() {
    return {
      id: generateUUID(),
    };
  },
  computed: {
    lValue: {
      get: function () {
        return this.lodash.get(this.value, this.path);
      },
      set: function (newValue) {
        this.lodash.set(this.value, this.path, newValue);
        this.$emit("input", this.value);
      },
    },
  },
};
</script>

<style lang="scss">
.custom-input:read-only {
  background-color: $main-1;
  //color: $highlight-text;
}
</style>
