<template>
  <div>
    <div class="d-flex justify-content-between">
      <h2 v-text="headerData.title"></h2>
      <div class="d-flex gap-3">
        <UIButton
          :text="headerData.text"
          :leftIcon="headerData.icon"
          @click="eventData"
        />
        <UIButton
          v-show="isEdit"
          text="Удалить"
          leftIcon="bi-trash"
          @click="deleteData"
        />
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col">
        <UIInput
          v-model="modelData.name"
          :validate="v$.modelData.name"
          :editable="editable"
          label="Наименование"
        />
        <UIInput
          v-model="modelData.value"
          :validate="v$.modelData.value"
          :editable="editable"
          label="Значение"
        />
        <UIInput
          v-model="modelData.code"
          :validate="v$.modelData.code"
          :editable="editable"
          label="Код"
        />
      </div>
      <div class="col">
        <UITextarea
          v-model="modelData.description"
          :editable="editable"
          label="Описание"
        />
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import UIButton from "@/components/UI/Button";
import UIInput from "@/components/UI/Input";
import UITextarea from "@/components/UI/Textarea";
import { required } from "@vuelidate/validators";
import {
  API_AllKindsInModelCreate,
  API_AllKindsInModelID,
  API_AllKindsInModelIDDELETE,
  API_AllKindsInModelIDPUT,
} from "@/services/kind";

export default {
  name: "viewEdit",
  components: { UIButton, UIInput, UITextarea },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      modelData: {
        name: "",
        code: "",
        value: "",
        description: "",
      },
    };
  },
  validations() {
    return {
      modelData: {
        name: { required },
        // code: { required },
        value: { required },
      },
    };
  },
  computed: {
    editable: function () {
      switch (this.$attrs.mode) {
        case "edit":
        case "create":
          return true;
        default:
          return false;
      }
    },
    isEdit: function () {
      return this.$attrs.mode === "edit";
    },
    headerData: function () {
      switch (this.$attrs.mode) {
        case "create":
          return {
            title: "Создание вида",
            text: "Сохранить",
            icon: "bi-save",
          };
        case "edit":
          return {
            title: "Редактирование вида",
            text: "Сохранить",
            icon: "bi-save",
          };
        default:
          return {
            title: "Просмотр вида",
            text: "Редактировать",
            icon: "bi-pencil",
          };
      }
    },
  },
  mounted() {
    if (this.$attrs.mode !== "create") {
      API_AllKindsInModelID(this.$route.params.modelName, this.$route.params.id)
        .then((res) => {
          this.modelData = res;
        })
        .catch(() => {
          this.$notify({
            type: "error",
            duration: 10000,
            text: "Неудалось загрузить запись",
          });
        });
    }
  },
  methods: {
    eventData() {
      switch (this.$attrs.mode) {
        case "create":
          this.createData();
          break;
        case "edit":
          this.saveData();
          break;
        case "view":
          this.$router.push({
            path: `/kind/${this.$route.params.modelName}/${this.$route.params.id}/edit`,
          });
          break;
      }
    },
    async createData() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      API_AllKindsInModelCreate(this.$route.params.modelName, this.modelData)
        .then(() => {
          this.$notify({
            type: "success",
            duration: 1000,
            text: "Запись создана",
          });
          this.$router.push({
            path: `/kind/list`,
          });
        })
        .catch(() => {
          this.$notify({
            type: "error",
            duration: 10000,
            text: "Неудалось создать запись",
          });
        });
    },
    async saveData() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      API_AllKindsInModelIDPUT(
        this.$route.params.modelName,
        this.$route.params.id,
        this.modelData
      )
        .then(() => {
          this.$notify({
            type: "success",
            duration: 1000,
            text: "Запись соханена",
          });
          this.$router.go(-1);
        })
        .catch(() => {
          this.$notify({
            type: "error",
            duration: 10000,
            text: "Неудалось сохранить запись",
          });
        });
    },
    async deleteData() {
      if (this.editable) {
        API_AllKindsInModelIDDELETE(
          this.$route.params.modelName,
          this.$route.params.id
        )
          .then(() => {
            this.$notify({
              type: "success",
              duration: 1000,
              text: "Запись удалена",
            });
            this.$router.push({
              path: `/kind/list`,
            });
          })
          .catch(() => {
            this.$notify({
              type: "error",
              duration: 10000,
              text: "Неудалось удалить запись",
            });
          });
      }
    },
  },
};
</script>

<style scoped></style>
