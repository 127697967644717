<template>
  <div class="mb-3">
    <label
      v-if="label"
      for="inputLabel"
      class="form-label"
      v-text="label"
    ></label>
    <textarea
      type="text"
      class="form-control custom-input"
      id="inputLabel"
      v-bind:value="value"
      :readonly="!editable"
      v-on:input="$emit('input', $event.target.value)"
      :class="{ 'is-invalid': validate.$error }"
    ></textarea>
    <div v-if="validate.$error && message" v-text="message"></div>
  </div>
</template>

<script>
export default {
  name: "Textarea",
  props: {
    value: {
      type: String,
      require: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: String,
    message: String,
    validate: {
      type: Object,
      default() {
        return {
          $error: false,
        };
      },
    },
  },
};
</script>

<style lang="scss">
.custom-input:read-only {
  background-color: $main-1;
  //color: $highlight-text;
}
</style>
